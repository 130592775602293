<template>
  <WidgetRender
    v-if="widgetModel"
    v-bind="{
      queryAsFilters,
      controls,
      contextToolbarControls,
      widgetModel,
      editMode: true,
      isDirty: true,
      isSaving,
    }"
    @exit-edit-mode="exitEditMode"
    @save="createWidget"
  />
</template>

<script lang="ts">
import Vue from 'vue'

import WidgetRender from '../components/WidgetRender.vue'
import WidgetPersister from '../lib/WidgetPersister'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetStoreInterface, WidgetStoreModel } from '@/tt-widget-factory'
import {
  bindBeforeUnload,
  unbindBeforeUnload,
} from '@/helpers/windowEvents/beforeUnload'
import { askConfirmationToClear } from '../lib/on-route-change-utils'
import { omitPushErrors } from '@/plugins/router'
import { WidgetEditorEvents } from '../types'
import { Routes } from '../enumRoutes'

const isDirty = true

export default Vue.extend({
  name: 'CreateWidgetView',
  components: {
    WidgetRender,
  },
  beforeRouteUpdate(_, __, next) {
    this.requestConfirmation(next)
  },
  beforeRouteLeave(_, __, next) {
    this.requestConfirmation(next)
  },
  props: {
    queryAsFilters: {
      type: Boolean,
      default: true,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    contextToolbarControls: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      widgetPersister: new WidgetPersister(this.$appContext),
      onRouteChangeActive: true,
      isSaving: false,
    }
  },
  computed: {
    newWidgetModel(): WidgetStoreInterface {
      return WidgetStoreModel.getNew()
    },
    widgetModel(): WidgetStoreInterface {
      return WidgetStoreModel.getSelected()
    },
  },
  watch: {
    newWidgetModel: {
      handler() {
        if (this.newWidgetModel) {
          WidgetStoreModel.setSelected(this.newWidgetModel)
          this.openEditPanel()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    bindBeforeUnload(this.$tc('widgets.discard_changes'), () => isDirty)
  },
  beforeDestroy() {
    unbindBeforeUnload()
  },
  methods: {
    openEditPanel() {
      this.$eventManager.dispatchEvent(WidgetEditorEvents.WIDGET_EDIT, {
        widgetType: this.newWidgetModel.is,
      })
    },
    createWidget(close: boolean): Promise<void> {
      this.isSaving = true

      return this.widgetPersister
        .createWidget(this.widgetModel)
        .then((response) => {
          this.onRouteChangeActive = false
          this.showSuccessMessage()
          this.goToWidgetViewRoute(close, response.uid)
        })
        .catch(() => this.showErrorMessage())
        .finally(() => (this.isSaving = false))
    },
    goToWidgetViewRoute(close: boolean, uid: string) {
      if (close) {
        this.closeSidePanel()
        this.$router
          .push({ name: Routes.WIDGET_VIEW, params: { viewId: uid } })
          .catch(omitPushErrors(['NavigationDuplicated']))
      } else {
        this.$router
          .push({ name: Routes.WIDGET_EDIT, params: { viewId: uid } })
          .catch(omitPushErrors(['NavigationDuplicated']))
      }
    },
    requestConfirmation(next) {
      askConfirmationToClear(this.$eventManager, this.onRouteChangeActive).then(
        () => next(),
        () => next(false),
      )
    },
    exitEditMode() {
      this.$router.push({ name: Routes.HOME })
    },
    showSuccessMessage() {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_SUCCESS,
        { message: this.$t('intents.widget_save.saved') },
      )
    },
    showErrorMessage() {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_ERROR,
        { message: this.$t('intents.widget_save.failed_to_save') },
      )
    },
    closeSidePanel() {
      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.SIDE_SHEET_CLOSE,
        () => ({}),
      )
    },
  },
})
</script>
